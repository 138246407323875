import ServiceCards, { ServiceCardsProps } from "./widgets/ServiceCards"
import { scoreImage } from "../assets"


const categories : ServiceCardsProps[]= [
    {
        title: "General Manager",
        image : scoreImage,
        link: "",
        description : "Learn when it takes to be manager"
    },
    {
        title: "Footballer",
        link: "",
        image : scoreImage,
        description : "The steps to becoming a footballer"
    },
    {
        title: "Agent",
        link: "",
        image : scoreImage,
        description : "Get to know how to be an agent"
    },
    {
        title : "Coach",
        link : "",
        image : scoreImage,
        description : "Begin the journey to be a coach"
    }
]

export default function Services() {
    return <section className="bg-aGameGray">
        <div className="my-container py-20">
            <h1 className="text-center text-4xl text-aGameBlue3">Our Categories</h1>
            <p className="text-3xl text-center mt-3">Unlock your potential in football</p>

            <div className="grid gap-12 md:grid-cols-2 mt-20">
                {
                    categories.map(
                        (category, index) => <ServiceCards left={index % 2 === 1} {...category} />
                    )
                }
            </div>
        </div>
    </section>
}