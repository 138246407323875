import { scoreImage } from "../assets";
import Button from "./widgets/Button";

export default function BridgingGap() {
    return <section className="my-container flex flex-col md:flex-row gap-10 items-center py-20">
        <div className="grid gap-3">
            <h1 className="text-4xl text-aGameBlue3">Bridging the gap </h1>
            <p className="text-3xl">Empowering nigerian football</p>
            <p className="text-gray-500 text-lg">At A-Game Info Hub, we empower aspiring professionals in the Nigerian football industry by providing precise and actionable information on various roles within professional football clubs. Our focus on Football Operations ensures that you receive comprehensive resources and career guidance to navigate your path in this dynamic field. We are committed to enhancing your understanding and skills, helping you to excel in your football career.</p>
            <a href="/">
                <Button className="border-black border-2 font-semibold">
                    Get in Touch
                </Button>
            </a>
        </div>

        <img alt="score" src={scoreImage} data-aos="fade-left" className="w-full md:max-w-xs lg:max-w-sm aspect-[1/1.2] object-cover"/>
    </section>
}