import { heroImage } from "../assets";
import Button from "./widgets/Button";

export default function Hero() {
    return <header className="relative -z-10 h-[65vh]">

        <img alt="hero" className="w-full h-full absolute top-0 object-cover" src={heroImage} />
        <div className=" bg-gradient-to-b from-[rgba(0,0,0,0.3)] 
        to-[rgba(0,0,0,0.4)] h-full relative z-10">
            <div className="my-container flex items-end h-full">
                <div className="bg-aGameBlue2/80 grid gap-5 h-fit w-full max-w-2xl lg:max-w-md p-10 text-white">
                    <h1 className="text-5xl lg:text-7xl font-semibold"
                        data-aos="fade-up"
                    >A-game Info Hub</h1>
                    <p className="text-xl lg:text-2xl font-semibold"
                        data-aos="fade-up"
                    >Empowering aspiring football professionals in Nigeria</p>
                    <a href="/">
                        <Button className="text-white bg-aGameBlue3">
                            View Categories
                        </Button>
                    </a>
                </div>

            </div>
        </div>
    </header>
}