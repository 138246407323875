const footerLinks = [
    {
        name : "Categories",
        link : "/#categories"
    },
    {
        name : "Schedule Appointments",
        link : "/"
    },
    {
        name : "Complete Intake",
        link : "/"
    },
    {
        name : "Contact",
        link : "/#contact"
    }
]

export default function Footer() {
    return <footer className="bg-aGameBlue3 text-white py-10">
        <div className="my-container flex flex-col items-center md:items-start">
            <a href="/"><h1 className="text-2xl text-right">
                A-Game Info Hub - Football Operations
            </h1></a>

            <ol className="flex flex-wrap justify-center md:justify-start pl-10 gap-2 mt-10 md:mt-2">
                {
                    footerLinks.map(
                        ({link, name}) => <li className="border-b-2 pb-1 mr-5 cursor-pointer hover:border-white border-transparent transition-all duration-700">
                            <a href={link}>
                                {name}
                            </a>
                        </li>
                    )
                }
            </ol>

            <p className="mt-20">&copy;{new Date().getFullYear()} Agame</p>
        </div>
    </footer>
}