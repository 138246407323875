import React from "react";

interface ButtonProps extends React.PropsWithChildren {
    className? : string,
    onClick ?: () => void
}
export default function Button(
    {
        className="",
        children,
        onClick
    }
    : ButtonProps
) {
    return <button onClick={onClick} className={`${className} min-h-12 px-7`}>
        {children}
    </button>
}