import Button from "./Button"

export interface ServiceCardsProps {
    image : string,
    title : string, 
    description : string,
    link : string,
    left ? : boolean
}

export default function ServiceCards({
    image, title, description, link, left = false
} : ServiceCardsProps) {
    return <div className="w-full aspect-[1/1.2] relative hover:scale-105 transition-all duration-500">
        <img src={image} alt={title} className="w-full h-full object-cover"/>

        <div className="absolute top-0 hover:bg-aGameGray/40 transition-all duration-500 h-full w-full">
            <div className={`bg-aGameGray2 min-h-[33%] w-10/12 absolute p-5 
            ${left ? "bottom-10 left-0" : "top-10 right-0"} flex flex-col justify-center gap-3`}>
                <p className="text-2xl">{title}</p>
                <p className="text-lg text-gray-500">{description}</p>
                <a href="/">
                    <Button className="border-2 border-black">
                        Learn More
                    </Button>
                </a>
            </div>
        </div>

    </div>
}