import React from "react"
import Button from "./widgets/Button"
import { hamburgerImage } from "../assets";
import SideBar, { navLinks } from "./SideBar";

export default function NavBar() {
    const [showNavName, setShowNavName] = React.useState(false);
    const [showSideBar, setShowSideBar] = React.useState(false);

    React.useEffect(() => {
        function handleScrollEvent(e: Event) {
            const scrollHeight = window.scrollY;
            if (scrollHeight > 110) {
                setShowNavName(true)
            } else {
                setShowNavName(false)
            }
        }
        document.addEventListener('scroll', handleScrollEvent)

        return function cleanup() {
            document.removeEventListener('scroll', handleScrollEvent)
        }
    }, [])
    return <>
        {/* The nav header */}
        <div className="hidden lg:flex justify-center py-3 text-2xl bg-aGameBlue text-white">
            A-Game Football
        </div>

        {/* The main nav */}
        <nav className={`bg-aGameBlue sticky px-[10px] top-0 text-white flex py-4 justify-between items-center shadow-lg z-10 ${showNavName ? "group showname" : ""}`}>
            <h1 className="lg:absolute my-auto font-semibold transition-all text-2xl
            lg:-left-32 lg:opacity-0 lg:group-[.showname]:left-[10px] lg:group-[.showname]:opacity-100">A-game Football</h1>
            <ol className="lg:flex hidden group-[.showname]:justify-end justify-center
            xl:group-[.showname]:justify-center items-center gap-10 w-full">
                {
                    navLinks.map(
                        ({ name, link }) => <li className="border-b-2 pb-1 cursor-pointer hover:border-white border-transparent transition-all duration-700">
                            <a href={`/#${link}`}>
                                {name}
                            </a>
                        </li>
                    )
                }

                <li>
                    <a href="/#contact">
                        <Button className="border-white border-2">
                            Contact
                        </Button>
                    </a>
                </li>
            </ol>

            <button className="block lg:hidden" onClick={() => setShowSideBar(true)}>
                <img src={hamburgerImage} alt="ham" className="w-10 h-10" />
            </button>
        </nav>

        {
            showSideBar ? <SideBar onClose={setShowSideBar}/> : <></>
        }
    </>
}