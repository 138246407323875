import React from "react"
import { closeImage } from "../assets"
import Button from "./widgets/Button"

export const navLinks = [
    {
        name: "Home",
        link: "/#home",
    },
    {
        name: "About",
        link: "/#about"
    },
    {
        name: "General Manager",
        link: "/#general-manager"
    },
    {
        name: "Footballer",
        link: "/#footballer"
    },
    {
        name: "Agent",
        link: "/#agent"
    },
    {
        name: "Coach",
        link: "/#coach"
    }
]


export default function SideBar(
    { onClose }: { onClose: React.Dispatch<React.SetStateAction<boolean>> }
) {
    return <div className="fixed z-20 bg-aGameBlue h-screen p-12 top-0 w-full text-white">
        <button onClick={() => onClose(false)}>
            <img alt="close" src={closeImage} className="absolute top-7 right-7 w-10 h-10" />
        </button>
        <ol className="grid gap-6">
            {
                navLinks.map(
                    ({ name, link }) => <li onClick={() => onClose(false)} className="w-full border-b border-white/70 pb-4 text-lg font-semibold">
                        <a href={link}>
                            {name}
                        </a>
                    </li>
                )
            }

            <li>
                <a href="/#contact">
                    <Button onClick={() => onClose(false)} className="border-white border-2 w-full">
                        Contact
                    </Button>
                </a>
            </li>
        </ol>
    </div>
}