import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import BridgingGap from "./components/BridgingGap";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";
import Services from "./components/Services";

function App() {
  React.useEffect(
    () => {
      AOS.init()
    }, []
  )
  return (
    <>
      <NavBar />
      <Hero />
      <BridgingGap />
      <Services />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
