import { mailImage } from "../assets";
import Button from "./widgets/Button";

const contactHours = {
    monday: "9:00am - 10:00pm",
    tuesday: "9:00am - 10:00pm",
    wednesday: "9:00am - 10:00pm",
    thursday: "9:00am - 10:00pm",
    friday: "9:00am - 10:00pm",
    saturday: "9:00am - 6:00pm",
    sunday: "9:00am - 12:00pm",
}

export default function Contact() {
    return <section className="my-container py-20 grid md:gap-0 gap-10 md:grid-cols-2 items-center">
        <div className="grid gap-4 pr-0 md:pr-5 lg:pr-14 w-full">
            <h1 className="text-aGameBlue3 text-4xl">Get in touch</h1>
            <p className="text-3xl">We're here to help</p>

            <form className="grid gap-4">
                <div className="grid gap-2">
                    <label>Name <span className="text-red-600">*</span></label>
                    <input size={1} className="border border-black rounded-sm focus:outline-2 
                    focus:outline focus:outline-offset-2 text-lg px-3 py-2" name="name" />
                </div>
                <div className="grid gap-2">
                    <label>Email Address <span className="text-red-600">*</span></label>
                    <input size={1} className="border border-black rounded-sm focus:outline-2 
                    focus:outline focus:outline-offset-2  text-lg px-3 py-2" name="email" />
                </div>

                <div className="grid gap-2">
                    <label>Phone Number <span className="text-red-600">*</span></label>
                    <input size={1} className="border border-black rounded-sm focus:outline-2 
                    focus:outline focus:outline-offset-2  text-lg px-3 py-2" name="email" />
                </div>
                <div className="grid gap-2">
                    <label>Message <span className="text-red-600">*</span></label>
                    <textarea className="border min-h-36 border-black focus:outline-2 
                    focus:outline focus:outline-offset-2  rounded-sm text-lg px-3 py-2" name="email" />
                </div>

                <div className="flex gap-2 items-start relative">
                    <input type="checkbox" className="relative top-[0.45rem]" />
                    <p className="text-lg">
                        I allow this website to store my submission so they can respond to my inquiry. *
                    </p>
                </div>

                <Button className="bg-aGameBlue3 text-white w-full">
                    Submit
                </Button>
            </form>
        </div>

        {/* Second */}
        <div className="bg-aGameGray p-5 sm:p-10 h-fit w-full text-aGameGray3 text-lg flex flex-col gap-2">
            <p className="text-black">Get in touch</p>
            <div className="flex gap-3 w-full">
                <img src={mailImage} alt="mail" className="w-5 h-5 shrink-0 break-words" />
                <a
                    href="mailto:ayodeji.ogundare@explorelearning.co.uk"
                    className="border-b block break-words w-[calc(100vw-100px)] sm:w-full border-black hover:border-aGameBlue3 hover:text-aGameBlue3">
                    ayodeji.ogundare@explorelearning.co.uk
                </a>
            </div>

            <p className="mt-2 text-black">Hours</p>
            <ol>
                {
                    Object.entries(contactHours).map(
                        ([key, value]) => <li className="flex gap-5">
                            <p className="capitalize shrink-0 w-24">{key}</p>
                            <p className="sm:w-full">{value}</p>
                        </li>
                    )
                }
            </ol>
        </div>
    </section>
}